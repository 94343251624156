import React from "react";

import imgC from "../../assets/image/home-5/png/camping.png";
import veganIcon from "../../assets/image/home-5/png/plant-based.png";
import notesIcon from "../../assets/image/home-5/png/notes.png";
import paperIcon from "../../assets/image/home-5/png/paper.png";

const Content1 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest} style={{ backgroundColor: "#E9E0D8" }}>
      <div className="container">
        {/* Section title */}
        <div className="row align-items-center justify-content-between">
          <div
            className="col-xl-6 col-lg-5 col-md-7 col-xs-8"
            data-aos="flip-right"
            data-aos-delay={500}
          >
            <div className="l4-image-group mt-lg-n18 mt-xl-n20 mb-8 mb-lg-0 ml-lg-n15 mr-xl-19">
              <img
                className="w-100"
                src={imgC}
                alt=""
                style={{ paddingTop: 100 }}
              />
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-7 col-md-8 pt-lg-23 pb-lg-18"
            data-aos="fade-right"
            data-aos-delay={500}
          >
            <h3 className="font-size-11 mb-0 mb-10 pr-xs-18 pr-md-0">
              Helping multi-disciplinary achievers
            </h3>
            <p className="font-size-7 mb-0 pr-xs-15 pr-md-0 pl-xl-2 mt-7 mt-lg-0 pr-lg-18">
              As a multi-disciplinary achiever, there are so many moving pieces
              in your life, we're here to help you thrive in the chaos.
            </p>
          </div>
        </div>
        {/* End Section title */}
        {/* Services */}
        <div className="pt-lg-9">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div>
                  <img
                    src={notesIcon}
                    style={{ maxWidth: 60, maxHeight: 60 }}
                  />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6">Lay Flat</h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    When this notebook is opened, let it do the work for you so
                    you have the time to focus on your goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div>
                  <img
                    src={veganIcon}
                    style={{ maxWidth: 60, maxHeight: 60 }}
                  />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6">Vegan Leather</h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    Beautiful and functional. The cover looks beautiful on your
                    desk and rugged on any adventures you take with it.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-xs-8">
              <div
                className="pt-10 pt-lg-18"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div>
                  <img
                    src={paperIcon}
                    style={{ maxWidth: 60, maxHeight: 60 }}
                  />
                </div>
                <div className="mt-9">
                  <h4 className="font-size-8 mb-6">Premium Paper</h4>
                  <p className="font-size-5 mb-0 pr-xl-8">
                    At 120 gsm, this beautiful ivory paper is thick and
                    heavyweight not allowing any bleed-through or ghosting.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Services */}
      </div>
    </div>
  );
};

export default Content1;
