import React, { useContext } from "react";
import ReactDOM from "react-dom";

import GlobalContext from "../../context/GlobalContext";
import imgH from "../../assets/image/home-5/png/adventure.jpeg";
import { backgroundColor } from "styled-system";

const Hero = ({ className, ...rest }) => {
  return (
    <div
      className={className}
      {...rest}
      style={{
        backgroundColor: "#F8F5F2",
        // backgroundImage: `url(${imgH})`,
        // backgroundPosition: "center",
        // backgroundSize: "cover",
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          {/* Hero Content */}
          <div className="col-lg-7 col-md-10 col-sm-12 order-2 order-lg-1">
            <div
              className="mt-8 mt-lg-0 mr-xl-13"
              data-aos="fade-right"
              data-aos-delay={500}
            >
              <h1 className="font-size-12 mb-9">
                Essential Tools for the Multi-Disciplinary Achiever.
              </h1>
              <p className="font-size-8 mb-lg-13 pr-sm-15 pr-lg-8 pr-xl-15">
                Take control of your time and achieve your goals.
              </p>
              {/* email sign up form **mailchimp** */}
              <div
                className="pr-lg-17 pr-xl-25"
                data-aos="fade-up"
                data-aos-duration={900}
                data-aos-once="true"
              >
                <p>
                  Sign-up now to be part of the #ThriveFamily and get your free
                  shipping code.
                </p>
                <form
                  action="https://gmail.us7.list-manage.com/subscribe/post?u=da9b414b78face958d29bda8b&amp;id=4d0c616ae7"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="validate"
                  target="_blank"
                  novalidate
                >
                  <div className="form-group mb-7">
                    <input
                      type="email"
                      name="EMAIL"
                      className="form-control form-control-lg bg-white rounded-4 text-bali-gray pl-8 font-size-5"
                      placeholder="Email Address"
                      id="email"
                    />
                  </div>
                  <div className="max-w-435">
                    {/* <a
                      href="#"
                      className="btn btn-blue-3 w-100 h-55 rounded-4"
                      style={{ backgroundColor: "#BFA68D", border: 0 }}
                    >
                      Send
                    </a> */}
                    <div class="clear">
                      <input
                        style={{
                          height: 55,
                          backgroundColor: "#BFA68D",
                          border: 0,
                          fontSize: 16,
                          color: "white",
                          borderRadius: 4,
                          minWidth: 193,
                          width: "100%",
                          fontWeight: 500,
                        }}
                        type="submit"
                        value="Subscribe"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        class="button"
                      />
                    </div>
                    <div id="mce-responses" className="clear">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="d-flex align-items-center py-6 mr-xl-15">
                {/* <div className="mr-7">
                  <a
                    href="/#"
                    className="bg-sunset text-white circle-76 video-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      gContext.toggleVideoModal();
                    }}
                  >
                    <i className="fas fa-play" />
                  </a>
                </div> */}
                {/* <div className="mt-0">
                  <h4 className="font-size-8 mb-0">Get started!</h4>
                  <p className="font-size-6 mb-0">
                    Watch latest trending features
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          {/* End Hero Content */}
          {/* Hero Image */}
          <div className="col-lg-5 col-md-7 col-xs-8 order-1 order-lg-2">
            <div className="pt-lg-3" data-aos="flip-right" data-aos-delay={900}>
              <img className="w-100" src={imgH} alt="" />
            </div>
          </div>
          {/* End Hero Image */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
