import React from "react";
import { Link } from "gatsby";

// import imgC1 from "../../assets/image/home-5/png/adventure.jpeg";
// import imgC2 from "../../assets/image/home-5/png/camping.png";
import imgC3 from "../../assets/image/home-5/png/notebook.jpeg";
import imgC4 from "../../assets/image/home-5/png/roomJournaling.jpeg";

const Content3 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest} style={{ backgroundColor: "#BFA68D" }}>
      <div className="container position-static">
        <div className="row align-items-center justify-content-center position-static">
          {/* Right Image */}
          <div className="col-xl-6 col-lg-5 col-md-9 position-static">
            {/* content-2 start */}
            <div className="l5-content-gallery-img">
              {/* content img start */}
              <div className="d-xs-flex ml-lg-15 mx-xl-n3">
                <div className="d-flex flex-column px-3 px-sm-6 w-100 mr-xl-14">
                  {/* single image start */}
                  {/* <img
                    style={{ maxWidth: 469 }}
                    src={imgC1}
                    alt=""
                    data-aos="zoom-in"
                    data-aos-duration={500}
                    className="w-100 pb-6 pb-sm-9 rounded-bottom-10"
                  /> */}
                  {/* single image end */}
                  {/* single image */}
                  {/* <img
                    src={imgC2}
                    alt=""
                    data-aos="zoom-in"
                    data-aos-duration={500}
                    data-aos-delay={700}
                    className="w-100 pb-6 pb-sm-9 rounded-10"
                  /> */}
                  {/* single image end */}
                  {/* single image */}
                  <img
                    style={{ maxWidth: 469 }}
                    src={imgC3}
                    alt=""
                    data-aos="zoom-in"
                    data-aos-duration={500}
                    data-aos-delay={700}
                    className="w-100 rounded-top-10"
                  />
                  <div style={{ height: 30 }} />
                  <img
                    style={{ maxWidth: 469 }}
                    src={imgC4}
                    alt=""
                    data-aos="zoom-in"
                    data-aos-duration={500}
                    data-aos-delay={700}
                    className="w-100 pb-6 pb-sm-9 rounded-10"
                  />
                  {/* single image end */}
                </div>
              </div>
              {/* abs-content end */}
            </div>
            {/* content-2 end */}
          </div>
          {/* End Right Image */}
          <div className="col-xl-6 col-lg-7 col-md-9">
            <div className="dark-mode-texts mt-10 mt-lg-0 pr-xs-15 pr-sm-14 pr-md-13 pr-lg-0 pl-lg-15">
              {/* <h6
                className="font-size-3 text-white text-uppercase mb-10 letter-spacing-5"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                Popular choice
              </h6> */}
              <h2
                className="font-size-10 mb-8 letter-spacing-n83  pr-xs-25 pr-sm-15 pr-md-15 pr-lg-0"
                data-aos="fade-up"
                data-aos-delay={500}
                style={{ color: "#1d293f" }}
              >
                Let us be your companion to your busy lifestyle. We want to help
                you plan, execute, and thrive.{" "}
              </h2>
              {/* <div className="mt-12" data-aos="fade-up" data-aos-delay={900}>
                <Link
                  to="/#"
                  className="btn btn-sunset btn-xl h-55 rounded-5"
                  style={{ backgroundColor: "rgb(191, 166, 141)" }}
                >
                  Start 14 Days trial
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content3;
